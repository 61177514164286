import { type Ctx, fetcher, formatParams } from 'api'
import { BASE_URL, API } from 'api/constants'
import urlHelper from 'app/helpers/url.helper'
import {
  CompaniesListSchema,
  Company,
  CompanyBankAccounts,
  CompanyBankAccount,
  CompanyCenters,
  CompanyConfiguration,
  IdReturn,
  CompanyBankAccountBody,
  PostCompany
} from 'api/models'
import { IdLabelItems } from 'api/models/commons'

export const companiesRouter = ({ token }: Ctx) => ({
  list: async (params: string) =>
    fetcher<CompaniesListSchema>({
      url: `${BASE_URL}${API.COMPANIES}${formatParams(params)}`,
      method: 'GET',
      token: token
    }),
  get: async (id: number) =>
    fetcher<Company>({
      url: `${BASE_URL}${urlHelper(API.COMPANY, { id })}`,
      method: 'GET',
      token: token
    }),
  getCenters: async (id: number) =>
    fetcher<CompanyCenters>({
      url: `${BASE_URL}${urlHelper(API.COMPANY_CENTERS, { id })}`,
      method: 'GET',
      token: token
    }),
  getConfigurations: async (id: number) =>
    fetcher<CompanyConfiguration>({
      url: `${BASE_URL}${urlHelper(API.COMPANY_CONFIG, { id })}`,
      method: 'GET',
      token: token
    }),
  getCompanyBankAccounts: async (id: number) =>
    fetcher<CompanyBankAccounts>({
      url: `${BASE_URL}${urlHelper(API.COMPANY_LIST_BANK_ACCOUNTS, { id })}`,
      method: 'GET',
      token: token
    }),
  createBankAccount: async (id: number, data: CompanyBankAccountBody) =>
    fetcher<IdReturn>({
      url: `${BASE_URL}${urlHelper(API.COMPANY_LIST_BANK_ACCOUNTS, { id })}`,
      method: 'POST',
      token: token,
      body: data
    }),
  updateBankAccount: async (id: number, data: CompanyBankAccountBody) =>
    fetcher<void>({
      url: `${BASE_URL}${urlHelper(API.COMPANY_BANK_ACCOUNT, { id })}`,
      method: 'PATCH',
      token: token,
      body: data
    }),
  getBankAccount: async (id: number) =>
    fetcher<CompanyBankAccount>({
      url: `${BASE_URL}${urlHelper(API.COMPANY_BANK_ACCOUNT, { id })}`,
      method: 'GET',
      token: token
    }),
  getAvailableCenters: async () =>
    fetcher<IdLabelItems>({
      url: `${BASE_URL}${API.COMPANIES}/available_centers`,
      method: 'GET',
      token: token
    }),
  postCompany: async (data: PostCompany) =>
    fetcher<void>({
      url: `${BASE_URL}${API.POST_COMPANY}`,
      method: 'POST',
      token: token,
      body: data
    })
})
